<template>
  <a-card class="w-100">
    <h6>
      Dados do imóvel
    </h6>
    <a-card class="bg-success" hoverable>
      <table class="table table-sm table-borderless text-white">
        <tr>
          <td class="text-right">
            <small>CEP:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.cep }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Rua:</small>
          </td>
          <td class="text-left h6">
            {{ bem.logradouro }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Quadra:</small>
          </td>
          <td class="text-left h6">
            {{ bem.quadra }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>N°:</small>
          </td>
          <td class="text-left h6">
            {{ bem.numero }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Bairro:</small>
          </td>
          <td class="text-left h6">
            {{ bem.bairro }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Cidade:</small>
          </td>
          <td class="text-left h6">
            {{ bem.localidade }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Complemento:</small>
          </td>
          <td class="text-left h6">
            {{ bem.complemento }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Estado:</small>
          </td>
          <td class="text-left h6">
            {{ bem.uf }}
          </td>
        </tr>
      </table>
    </a-card>
    <h6 class="mt-4">
      Os seus dados estão corretos?
    </h6>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como podemos chamar você</small><br />
        {{ formAuto.nome_cliente }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como prefere ser atendido(a)</small><br />
        {{
          formAuto.tipo_atendimento === "W"
            ? "Whatsapp(recomendado)"
            : "Telefone, prefiro que me liguem"
        }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Contato</small><br />
        {{ formAuto.celular | filterPhone }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Residência própria ou alugada?</small><br />
        {{ formAuto.tipo_residencia === "P" ? "Própria" : "Alugada" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Casa ou apartamento?</small><br />
        {{ formAuto.tipo_imovel === "C" ? "Casa" : "Apartamento" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted"
          >Qual o valor estimado de avaliação de sua residencia (Imóvel e
          moveis)</small
        ><br />
        {{ formAuto.valor_estimado | filterCurrency }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Já possui seguro</small><br />
        {{ formAuto.possui_seguro === 1 ? "Sim" : "Não" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3" v-if="formAuto.possuiSeguro === 1">
        <small class="text-muted">Qual a seguradora</small><br />
        {{ formAuto.id_seguradora | filterSeguradoras(stateSeguradoras) }}
      </div>
    </div>
    <div class="row" v-if="formAuto.possui_seguro === 1">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual a classe de bônus</small><br />
        {{ formAuto.classe_bonus }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Houve sinistro na última vigência</small
        ><br />
        {{ formAuto.houve_sinistro === 1 ? "Sim" : "Não" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <div v-if="formAuto.cpf !== ''">
          <small class="text-muted">CPF:</small><br />
          {{ formAuto.cpf | filterCpfCnpj("CPF") }}
        </div>
        <div v-if="formAuto.cnpj !== ''">
          <small class="text-muted">CNPJ:</small><br />
          {{ formAuto.cnpj | filterCpfCnpj("CNPJ") }}
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Data de nascimento</small><br />
        {{ formAuto.nascimento | filterDate }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Estado civil</small><br />
        {{ formAuto.id_estado_civil | filterEstadosCivis(stateEstadosCivis) }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual o seu e-mail</small><br />
        <span class="text-lowercase">
          {{ formAuto.email }}
        </span>
      </div>
    </div>
  </a-card>
</template>

<script>
import "@/filters/generic-filters/filter-arrays";
import "@/filters/generic-filters/filter-date";
import "@/filters/generic-filters/filter-phone";
import "@/filters/generic-filters/filter-cpf-cnpj";
import "@/filters/generic-filters/filter-currency";
import { mapGetters } from "vuex";
export default {
  props: ["formAuto"],
  data() {
    return {
      auto: {},
      bem: {},
    };
  },
  watch: {
    formAuto: {
      immediate: true,
      handler(newVal) {
        this.auto = Object.assign({}, newVal);
        this.bem = Object.assign({}, JSON.parse(newVal.bem));
      },
    },
  },
  computed: {
    ...mapGetters([
      "stateSeguradoras",
      "stateEstadosCivis",
      "stateMarcasCarros",
    ]),
  },
};
</script>
