var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('drawer',{attrs:{"showDrawer":this.drawerView,"showHeader":true,"showFooter":false,"showBtnClose":true,"size":'40%',"title":'Visualização',"description":'Dados do usuário'},on:{"emit-drawer-closed":_vm.handleCloseDrawer}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Alterar status")]),_c('a-select',{staticClass:"w-100",attrs:{"show-search":"","size":"large","filterOption":_vm.filterOption},on:{"change":function($event){return _vm.atualizarStatusCotacao($event)}}},_vm._l((_vm.stateStatus),function(status,key){return _c('a-select-option',{key:key,attrs:{"value":status.id}},[_vm._v(" "+_vm._s(status.nome)+" ")])}),1)],1),(this.cotacao.id_categoria === 1)?_c('SeguroParaAutomovelDetalhamento',{attrs:{"formAuto":this.cotacao}}):_vm._e(),(this.cotacao.id_categoria === 2)?_c('SeguroParaMotoDetalhamento',{attrs:{"formAuto":this.cotacao}}):_vm._e(),(this.cotacao.id_categoria === 3)?_c('SeguroParaCasaDetalhamento',{attrs:{"formAuto":this.cotacao}}):_vm._e(),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '20px',
      background: '#fff',
      textAlign: 'center',
      zIndex: 1,
    })},[_c('a-button',{attrs:{"size":"large"},on:{"click":_vm.handleCloseDrawer}},[_vm._v("Fechar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }