<template>
  <a-card class="w-100">
    <h6>
      Dados da sua moto
    </h6>
    <a-card class="bg-success text-center" hoverable>
      <h5 class="text-white text-uppercase">
        {{ bem.marca }}
      </h5>
      <h6 class="text-white text-uppercase">
        {{ bem.veiculo }}
      </h6>
      <h6 class="text-white text-uppercase">
        {{ bem.ano_modelo }} |
        {{ bem.combustivel }}
      </h6>
    </a-card>
    <h6 class="mt-4">
      Os seus dados estão corretos?
    </h6>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como podemos chamar você</small><br />
        {{ formAuto.nome_cliente }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como prefere ser atendido(a)</small><br />
        {{
          formAuto.tipo_atendimento === "W"
            ? "Whatsapp(recomendado)"
            : "Telefone, prefiro que me liguem"
        }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Contato</small><br />
        {{ formAuto.celular }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Placa</small><br />
        <span class="text-uppercase">
          {{ formAuto.placa }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Chassi:</small><br />
        <span class="text-uppercase">
          {{ formAuto.chassi }}
        </span>
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual a utilização da sua moto?</small><br />
        {{ formAuto.tipo_utilizacao === "P" ? "Particular" : "Comercial" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Já possui seguro</small><br />
        {{ formAuto.possui_seguro === 1 ? "Sim" : "Não" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3" v-if="formAuto.possuiSeguro === 1">
        <small class="text-muted">Qual a seguradora</small><br />
        {{ formAuto.id_seguradora | filterSeguradoras(stateSeguradoras) }}
      </div>
    </div>
    <div class="row" v-if="formAuto.possui_seguro === 1">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual a classe de bônus</small><br />
        {{ formAuto.classe_bonus }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Houve sinistro na última vigência</small
        ><br />
        {{ formAuto.houve_sinistro === 1 ? "Sim" : "Não" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted"
          >Cobertura para condutor(a) entre 18 a 25 anos</small
        ><br />
        {{ formAuto.cobertura_1825 === 1 ? "Sim" : "Não" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <div v-if="formAuto.cpf !== ''">
          <small class="text-muted">CPF:</small><br />
          {{ formAuto.cpf }}
        </div>
        <div v-if="formAuto.cnpj !== ''">
          <small class="text-muted">CNPJ:</small><br />
          {{ formAuto.cnpj }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Data de nascimento</small><br />
        {{ formAuto.nascimento }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">CEP</small><br />
        {{ formAuto.cep }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Estado civil</small><br />
        {{ formAuto.id_estado_civil | filterEstadosCivis(stateEstadosCivis) }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Quantos anos de habilitação possui</small
        ><br />
        {{
          formAuto.anos_habilitacao > 1
            ? `${formAuto.anos_habilitacao} anos`
            : `${formAuto.anos_habilitacao} ano`
        }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual o seu e-mail</small><br />
        <span class="text-lowercase">
          {{ formAuto.email }}
        </span>
      </div>
    </div>
  </a-card>
</template>

<script>
import "@/filters/generic-filters/filter-arrays";
import { mapGetters } from "vuex";
export default {
  props: ["formAuto"],
  data() {
    return {
      auto: {},
      bem: {},
    };
  },
  watch: {
    formAuto: {
      immediate: true,
      handler(newVal) {
        this.auto = Object.assign({}, newVal);
        this.bem = Object.assign({}, JSON.parse(newVal.bem));
      },
    },
  },
  computed: {
    ...mapGetters([
      "stateSeguradoras",
      "stateEstadosCivis",
      "stateMarcasCarros",
    ]),
  },
};
</script>
