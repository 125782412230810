<template>
  <drawer
    :showDrawer="this.drawerView"
    :showHeader="true"
    :showFooter="false"
    :showBtnClose="true"
    :size="'40%'"
    :title="'Visualização'"
    :description="'Dados do usuário'"
    @emit-drawer-closed="handleCloseDrawer"
  >
    <div class="form-group">
      <label>Alterar status</label>
      <a-select
        show-search
        class="w-100"
        size="large"
        :filterOption="filterOption"
        @change="atualizarStatusCotacao($event)"
      >
        <a-select-option
          :value="status.id"
          v-for="(status, key) in stateStatus"
          :key="key"
        >
          {{ status.nome }}
        </a-select-option>
      </a-select>
    </div>
    <SeguroParaAutomovelDetalhamento
      v-if="this.cotacao.id_categoria === 1"
      :formAuto="this.cotacao"
    />
    <SeguroParaMotoDetalhamento
      v-if="this.cotacao.id_categoria === 2"
      :formAuto="this.cotacao"
    />
    <SeguroParaCasaDetalhamento
      v-if="this.cotacao.id_categoria === 3"
      :formAuto="this.cotacao"
    />
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '20px',
        background: '#fff',
        textAlign: 'center',
        zIndex: 1,
      }"
    >
      <a-button size="large" @click="handleCloseDrawer">Fechar</a-button>
    </div>
  </drawer>
</template>

<script>
import Drawer from "@/components/generic-components/drawer/drawer";
import SeguroParaAutomovelDetalhamento from "./seguro-para-automovel-detalhamento";
import SeguroParaMotoDetalhamento from "./seguro-para-moto-detalhamento";
import SeguroParaCasaDetalhamento from "./seguro-para-casa-detalhamento";
import { mapGetters, mapActions } from "vuex";
import "@/filters/generic-filters/filter-datetime";
import "@/filters/generic-filters/filter-date";

export default {
  props: ["cotacao", "drawerView"],
  computed: {
    ...mapGetters(["stateStatus"]),
  },
  components: {
    Drawer,
    SeguroParaAutomovelDetalhamento,
    SeguroParaMotoDetalhamento,
    SeguroParaCasaDetalhamento,
  },
  methods: {
    ...mapActions(["actionAtualizarStatusCotacao"]),
    handleCloseDrawer() {
      this.$emit("emit-close-drawer", false);
    },
    atualizarStatusCotacao(idStatus) {
      this.actionAtualizarStatusCotacao({
        id_status: idStatus,
        id_cotacao: this.cotacao.id,
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
