<template>
  <div class="mt-3">
    <a-table
      :columns="columns"
      :data-source="stateCotacoes"
      size="small"
      rowKey="id"
      :loading="loading"
    >
      <template slot="nomeCliente" slot-scope="scopeNomeCliente, row">
        <h5 class="m-0">
          {{ scopeNomeCliente }}
        </h5>
        <small>
          {{ row.email }}
        </small>
      </template>
      <template slot="status" slot-scope="scopeStatus">
        <a-tag
          :color="scopeStatus | filterStatusColor(stateStatus)"
          v-if="scopeStatus"
          class="text-dark"
        >
          {{ scopeStatus | filterStatus(stateStatus) }}
        </a-tag>
      </template>
      <template slot="celular" slot-scope="scopeCelular, tipo">
        <a
          v-if="tipo.tipo_atendimento === 'W'"
          :href="`https://wa.me/55${scopeCelular}`"
          target="_blank"
          class="text-dark"
        >
          <img :src="loadAsset('whatsapp.png')" width="20" class="align-wpp" />
          {{ scopeCelular | filterPhone }}
        </a>
        <span v-if="tipo.tipo_atendimento === 'T'">
          {{ scopeCelular | filterPhone }}
        </span>
      </template>
      <template slot="desistiu" slot-scope="scopeDesistiu, desistiu">
        <a-tag color="#ffcdd2" class="text-danger" v-if="!desistiu.email">
          Desistiu
        </a-tag>
        <a-tag color="#c5e1a5" class="text-dark" v-if="desistiu.email">
          Dados completos
        </a-tag>
      </template>
      <template slot="idCategoria" slot-scope="scopeIdCategoria">
        <h5>
          <img
            v-if="scopeIdCategoria === 1"
            :src="loadAsset('cotacao/carro.png')"
            width="25"
          />
          <img
            v-if="scopeIdCategoria === 2"
            :src="loadAsset('cotacao/moto.png')"
            width="25"
          />
          <img
            v-if="scopeIdCategoria === 3"
            :src="loadAsset('cotacao/casa.png')"
            width="25"
          />
        </h5>
      </template>
      <template slot="createdAt" slot-scope="scopeCreatedAt">
        {{ scopeCreatedAt | filterDateTime }}
      </template>
      <template slot="updatedAt" slot-scope="scopeUpdatedAt">
        {{ scopeUpdatedAt | filterDateTime }}
      </template>
      <span slot="action" slot-scope="text, record" v-if="record.email">
        <a-tooltip :mouseEnterDelay="0">
          <template slot="title">Visualizar</template>
          <b-icon-eye class="cursor-pointer h5" @click="visualizar(record)" />
        </a-tooltip>
      </span>
    </a-table>
  </div>
</template>

<script>
import "@/filters/generic-filters/filter-datetime";
import "@/filters/generic-filters/filter-arrays";
import "@/filters/generic-filters/filter-phone";
import { mapGetters } from "vuex";
export default {
  props: ["stateCotacoes"],
  computed: {
    ...mapGetters(["stateCategoriasSeguro", "loading", "stateStatus"]),
  },
  data() {
    return {
      columns: [
        {
          dataIndex: "nome_cliente",
          title: "Nome",
          sortable: true,
          sortDirection: "desc",
          scopedSlots: { customRender: "nomeCliente" },
        },
        {
          dataIndex: "id_status",
          title: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          scopedSlots: { customRender: "status" },
        },
        {
          dataIndex: "celular",
          title: "Telefone",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          scopedSlots: { customRender: "celular" },
        },
        {
          dataIndex: "id_categoria",
          title: "Categoria",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          scopedSlots: { customRender: "idCategoria" },
        },
        {
          title: "Desistiu?",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          scopedSlots: { customRender: "desistiu" },
        },
        {
          dataIndex: "created_at",
          title: "Início da cotação",
          class: "text-center",
          sortable: true,
          scopedSlots: { customRender: "createdAt" },
        },
        {
          dataIndex: "updated_at",
          title: "Fim da cotação",
          sortable: true,
          class: "text-center",
          scopedSlots: { customRender: "updatedAt" },
        },
        {
          dataIndex: "actions",
          title: "Ações",
          class: "text-center",
          scopedSlots: { customRender: "action" },
        },
      ],
      cotacao: {},
    };
  },
  methods: {
    visualizar(cotacao) {
      this.$emit("emit-show-drawer-view", Object.assign({}, cotacao));
    },
    loadAsset(path) {
      return require(`@/assets/img/${path}`);
    },
  },
};
</script>
