<template>
  <div>
    <a-card class="mb-3">
      <div class="row">
        <div class="col">
          <a-button
            type="dashed"
            @click="listarCotacoes()"
            shape="circle"
            class="mr-2"
          >
            <a-icon type="reload" />
          </a-button>
        </div>
        <div class="col-2">
          <label>Status</label>
          <a-select
            default-value="Todos"
            show-search
            class="w-100"
            size="large"
            :filterOption="filterOption"
            v-model="idStatus"
          >
            <a-select-option :value="0">
              Todos
            </a-select-option>
            <a-select-option
              :value="status.id"
              v-for="(status, key) in stateStatus"
              :key="key"
            >
              {{ status.nome }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-2">
          <label>Categorias</label>
          <a-select
            default-value="Todos"
            show-search
            class="w-100"
            size="large"
            :filterOption="filterOption"
            v-model="idCategoria"
          >
            <a-select-option :value="0">
              Todos
            </a-select-option>
            <a-select-option
              :value="categoria.id"
              v-for="(categoria, key) in stateCategoriasSeguro"
              :key="key"
            >
              {{ categoria.tipo }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-2 d-flex align-items-end">
          <a-button
            size="large"
            block
            class="bg-success text-white"
            @click="listarCotacoes()"
          >
            Filtrar
          </a-button>
        </div>
      </div>
    </a-card>
    <a-card>
      <CotacaoTable
        :stateCotacoes="stateCotacoes"
        :loading="loading"
        @emit-show-drawer-form="handleShowDrawerForm"
        @emit-show-drawer-view="handleShowDrawerView"
        @emit-show-drawer-edit="handleShowDrawerEdit"
      />
    </a-card>
    <CotacaoDrawerForm
      :showDrawer="drawerForm"
      @emit-close-drawer="handleCloseDrawerForm"
    />
    <CotacaoDrawerView
      :drawerView="drawerView"
      :cotacao="cotacao"
      @emit-close-drawer="handleCloseDrawerView"
    />
  </div>
</template>

<script>
import Cotacao from "@/model/cotacao/cotacao";
import CotacaoTable from "./cotacoes-table";
import CotacaoDrawerForm from "./cotacoes-drawer-form";
import CotacaoDrawerView from "./cotacoes-drawer-view";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CotacaoTable,
    CotacaoDrawerForm,
    CotacaoDrawerView,
  },
  computed: {
    ...mapGetters([
      "stateCotacoes",
      "loading",
      "stateCategoriasSeguro",
      "stateStatus",
    ]),
  },
  data() {
    return {
      drawerForm: false,
      drawerView: false,
      closeDrawerView: false,
      closeDrawerForm: false,
      cotacao: [],
      idCategoria: 0,
      idStatus: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.listarCotacoes(0, 0);
    });
  },
  methods: {
    ...mapActions(["actionListarCotacoes"]),
    listarCotacoes() {
      this.actionListarCotacoes({
        idCategoria: this.idCategoria,
        idStatus: this.idStatus,
      });
    },
    handleShowDrawerForm() {
      this.drawerForm = true;
      this.cotacao = new Cotacao();
    },
    handleCloseDrawerForm(closeDrawer) {
      this.drawerForm = closeDrawer;
      this.cotacao = new Cotacao();
    },
    handleShowDrawerEdit(cotacao) {
      this.drawerForm = true;
      this.cotacao = cotacao;
    },
    handleCloseDrawerEdit(closeDrawer) {
      this.drawerForm = closeDrawer;
      this.cotacao = new Cotacao();
    },
    handleShowDrawerView(cotacao) {
      this.cotacao = cotacao;
      this.drawerView = true;
    },
    handleCloseDrawerView(closeDrawer) {
      this.drawerView = closeDrawer;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    loadAsset(path) {
      return require(`@/assets/img/${path}`);
    },
  },
};
</script>
