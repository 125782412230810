module.exports = class Cotacao {
  constructor() {
    this.id = "",
    this.id_categoria = "",
    this.nome_cliente = "",
    this.tipo_atendimento = "",
    this.celular = "",
    this.id_marca = "",
    this.id_modelo = "",
    this.ano_modelo = "",
    this.bem = "",
    this.placa = "",
    this.chassi = "",
    this.tipo_utilizacao = "",
    this.possui_seguro = "",
    this.id_seguradora = "",
    this.classe_bonus = "",
    this.houve_sinistro = "",
    this.cobertura_1825 = "",
    this.cpf = "",
    this.nascimento = "",
    this.cep = "",
    this.id_estado_civil = "",
    this.anos_habilitacao = "",
    this.email = "",
    this.st_ativo = "",
    this.st_registro_ativo = "",
    this.created_at = "",
    this.updated_at = ""
  }
};

