<template>
  <drawer
    :showDrawer="this.showDrawer"
    :size="'40%'"
    :title="this.gestorForm.id ? 'Editar' : 'Cadastrar'"
    :description="'Cadastro de um novo usuário do sistema'"
    @emit-drawer-closed="handleCloseDrawer"
  >
    <div class="card border-0">
      <form class="card-body">
        <div class="form-group">
          <label>Nome</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="gestorForm.nome"
          />
        </div>
        <div class="form-group">
          <label>E-mail/Usuário</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="gestorForm.usernamemail"
          />
        </div>
        <div class="form-group">
          <label>Senha</label>
          <input
            type="password"
            class="form-control"
            v-model.trim="gestorForm.password"
          />
        </div>
        <div class="form-group">
          <label>CPF</label>
          <input
            type="text"
            class="form-control"
            :maxLength="14"
            v-mask="gestorForm.maskCPF"
            v-model="gestorForm.cpf"
          />
        </div>
        <div class="form-group">
          <label>Nascimento</label>
          <a-input
            v-mask="gestorForm.maskNascimento"
            v-model.trim="gestorForm.nascimento"
            :maxLength="10"
            size="large"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label>Telefone</label>
          <a-input
            v-mask="gestorForm.maskTelefone"
            v-model="gestorForm.telefone"
            :maxLength="14"
            size="large"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label>Gênero</label>
          <div>
            <a-select class="w-100" v-model="gestorForm.genero" size="large">
              <a-select-option
                :value="genero.id"
                v-for="genero in this.generos"
                :key="genero.id"
              >
                {{ genero.nome }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group">
          <label>Endereço</label>
          <input
            type="text"
            class="form-control"
            v-model="gestorForm.endereco"
          />
        </div>
      </form>
    </div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '20px',
        background: '#fff',
        textAlign: 'center',
        zIndex: 1,
      }"
    >
      <a-button size="large" class="mr-2" @click="handleCloseDrawer"
        >Cancelar
      </a-button>
      <a-button
        v-if="this.gestorForm.id"
        size="large"
        class="mr-2"
        type="primary"
        @click="handleEdit"
        >Concluir edição
      </a-button>
      <a-button
        v-else
        size="large"
        type="primary"
        @click="validarDadosAoCriarGestor"
        >Cadastrar
      </a-button>
    </div>
  </drawer>
</template>

<script>
import Cotacao from "@/model/cotacao/cotacao";
import Drawer from "@/components/generic-components/drawer/drawer";
import { mapActions } from "vuex";
import { Notification } from "@/util/noty";

export default {
  props: ["gestor", "showDrawer"],
  components: {
    Drawer,
  },
  data() {
    return {
      gestorForm: {
        nome: "",
        usernamemail: "",
        password: "",
        cpf: "",
        maskCPF: "###.###.###-##",
        nascimento: "",
        maskNascimento: "##/##/####",
        telefone: "",
        maskTelefone: "(##)#####-####",
        genero: "",
        endereco: "",
        uf: "",
        municipio_id: "",
      },
      generos: [
        {
          id: 1,
          nome: "Masculino",
        },
        {
          id: 2,
          nome: "Feminino",
        },
        {
          id: 3,
          nome: "Outro",
        },
      ],
      errors: [],
    };
  },
  watch: {
    gestor: function(obj) {
      this.gestorForm = {
        id: obj.id,
        nome: obj.gestor !== undefined ? obj.nome : "",
        usernamemail: obj.gestor !== undefined ? obj.usernamemail : "",
        password: obj.gestor !== undefined ? obj.password : "",
        cpf: obj.gestor !== undefined ? obj.gestor.cpf : "",
        maskCPF: "###.###.###-##",
        nascimento:
          obj.gestor !== undefined
            ? this.$options.filters.filterDate(obj.gestor.nascimento)
            : "",
        maskNascimento: "##/##/####",
        telefone: obj.gestor !== undefined ? obj.gestor.telefone : "",
        maskTelefone: "(##)#####-####",
        genero: obj.gestor !== undefined ? obj.gestor.genero : "",
        endereco: obj.gestor !== undefined ? obj.gestor.endereco : "",
        uf: obj.gestor !== undefined ? obj.gestor.uf : "",
        municipio_id: obj.gestor !== undefined ? obj.municipio_id : "",
        idGestor: obj.gestor !== undefined ? obj.gestor.id : "",
      };
    },
  },
  methods: {
    ...mapActions(["criarGestor", "editarGestor"]),
    handleCriarGestor() {
      this.criarGestor(this.gestorForm);
      this.handleCloseDrawer();
    },
    validarDadosAoCriarGestor() {
      this.errors = "";
      const {
        nome,
        usernamemail,
        password,
        cpf,
        nascimento,
        telefone,
        genero,
        endereco,
        uf,
        municipio_id,
      } = this.gestorForm;
      if (
        nome &&
        usernamemail &&
        password &&
        cpf &&
        nascimento &&
        telefone &&
        genero &&
        endereco &&
        uf &&
        municipio_id
      ) {
        this.handleCriarGestor();
      }

      if (!nome) {
        this.errors += "- Nome é obrigatório. <br/>";
      }

      if (!usernamemail) {
        this.errors += "- E-mail/Usuário é obrigatório. <br/>";
      }

      if (!password) {
        this.errors += "- Senha é obrigatório. <br/>";
      }

      if (!cpf) {
        this.errors += "- CPF é obrigatório. <br/>";
      }

      if (!nascimento) {
        this.errors += "- Nascimento é obrigatório. <br/>";
      }

      if (!telefone) {
        this.errors += "- Telefone é obrigatório. <br/>";
      }

      if (!genero) {
        this.errors += "- Gênero é obrigatório. <br/>";
      }

      if (!endereco) {
        this.errors += "- Endereço é obrigatório. <br/>";
      }

      if (!municipio_id) {
        this.errors += "- Município é obrigatório. <br/>";
      }

      this.errors.length > 0 && Notification("info", "Ooops!", this.errors);
    },
    handleEdit() {
      this.editarGestor(this.gestorForm);
    },
    handleCloseDrawer() {
      this.gestorForm = new Cotacao();
      this.$emit("emit-close-drawer", false);
    },
    municipioSelecionado(municipioSelecionado) {
      let split = municipioSelecionado.split(",");
      this.gestorForm.municipio_id = split[0];
      this.gestorForm.uf = split[1];
    },
  },
};
</script>
