<template>
  <a-drawer
    :title="this.title"
    :width="size"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleCloseDrawer"
  >
    <slot></slot>
  </a-drawer>
</template>

<script>
export default {
  props: ["title", "description", "showDrawer", "size"],
  data() {
    return {
      visible: Boolean(this.showDrawer),
    };
  },
  updated() {
    this.visibleDrawer(this.showDrawer);
  },
  methods: {
    visibleDrawer(visible) {
      this.visible = Boolean(visible);
    },
    handleShowDrawer() {
      this.$emit("emit-drawer-opened", true);
    },
    handleCloseDrawer() {
      this.$emit("emit-drawer-closed", false);
    },
  },
};
</script>

<style scoped>
.drawer {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
}
.drawer-header {
  padding: 1.5rem;
}
.drawer-content {
  overflow-y: scroll;
  padding: 1.5rem;
  padding-bottom: 6.5rem;
  flex: 1;
}
.drawer-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 1.5rem;
}
</style>
