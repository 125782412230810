/**
 * Formatar CPF ou CNPJ
 */
import Vue from "vue";
Vue.filter("filterCpfCnpj", (documentId, documentType) => {
  documentId =
    typeof documentId != "string" ? documentId.toString() : documentId;
  if (documentType === "CNPJ") {
    documentId = documentId.padStart(14, "0");
    documentId = documentId.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else if (documentType === "CPF") {
    documentId = documentId.padStart(11, "0");
    documentId = documentId.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
  } else if (documentType === "CEP") {
    documentId = documentId.padStart(8, "0");
    documentId = documentId.replace(/^(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
  }
  return documentId;
});
