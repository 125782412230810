import { render, staticRenderFns } from "./seguro-para-casa-detalhamento.vue?vue&type=template&id=fc1f6d7a&"
import script from "./seguro-para-casa-detalhamento.vue?vue&type=script&lang=js&"
export * from "./seguro-para-casa-detalhamento.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports